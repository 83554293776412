// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["hE1g9FTsF"];

const serializationHash = "framer-5XLSZ"

const variantClassNames = {hE1g9FTsF: "framer-v-1wx1rgr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tag, width, ...props}) => { return {...props, jLtk1KXFw: tag ?? props.jLtk1KXFw ?? "Tag"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tag?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jLtk1KXFw, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hE1g9FTsF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wx1rgr", className, classNames)} data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"hE1g9FTsF"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1yexm42"} layoutDependency={layoutDependency} layoutId={"sNkvWagr9"} style={{backgroundColor: "var(--token-c7439a68-040b-45cb-a4f1-224039bef410, rgba(0, 0, 0, 0.1))", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FzcGVrdGEgNDAw", "--framer-font-family": "\"Aspekta 400\", \"Aspekta 400 Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-letter-spacing": "0px", "--framer-line-height": "22px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38)))"}}>Tag</motion.p></React.Fragment>} className={"framer-1im2yhu"} fonts={["CUSTOM;Aspekta 400"]} layoutDependency={layoutDependency} layoutId={"XWGSNcy82"} style={{"--extracted-r6o4lv": "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={jLtk1KXFw} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5XLSZ.framer-1wtlsep, .framer-5XLSZ .framer-1wtlsep { display: block; }", ".framer-5XLSZ.framer-1wx1rgr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-5XLSZ .framer-1yexm42 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 6px 15px 6px 15px; position: relative; width: min-content; }", ".framer-5XLSZ .framer-1im2yhu { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5XLSZ.framer-1wx1rgr, .framer-5XLSZ .framer-1yexm42 { gap: 0px; } .framer-5XLSZ.framer-1wx1rgr > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5XLSZ.framer-1wx1rgr > :first-child, .framer-5XLSZ .framer-1yexm42 > :first-child { margin-left: 0px; } .framer-5XLSZ.framer-1wx1rgr > :last-child, .framer-5XLSZ .framer-1yexm42 > :last-child { margin-right: 0px; } .framer-5XLSZ .framer-1yexm42 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 56
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"jLtk1KXFw":"tag"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerM6IplQ_OO: React.ComponentType<Props> = withCSS(Component, css, "framer-5XLSZ") as typeof Component;
export default FramerM6IplQ_OO;

FramerM6IplQ_OO.displayName = "Accordion / Accordion Tag";

FramerM6IplQ_OO.defaultProps = {height: 34, width: 56};

addPropertyControls(FramerM6IplQ_OO, {jLtk1KXFw: {defaultValue: "Tag", displayTextArea: false, title: "Tag", type: ControlType.String}} as any)

addFonts(FramerM6IplQ_OO, [{explicitInter: true, fonts: [{family: "Aspekta 400", source: "custom", url: "https://framerusercontent.com/assets/AEfYlIhKbMvfYJjz64K4roBkv1k.woff2"}]}], {supportsExplicitInterCodegen: true})